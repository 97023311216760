.player-window {
    width: 100%;
}

.clappr-wrapper {
    position: relative;
    padding-bottom: 56.25%;
}

.clappr-player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.media-parent {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    height: auto;
    margin-top: 15px;
}
.media-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: none!important;
    min-height: 0!important;
    max-width: 100%;
    width: 100%;
}