.component-appear {
    opacity: 0;
}

.component-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
}

.component-exit {
    opacity: 1;
}

.component-exit-active {
    opacity: 0;
    transition: opacity 200ms linear;
}

.loading-exit {
    opacity: 1;
}

.loading-exit-active {
    opacity: 0;
    transition: opacity 200ms linear;
}